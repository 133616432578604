/*=========================================================================================
  File Name: moduleKinshipTypeMutations.js
  Description: KinshipType Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.KinshipTypes.unshift(item)
  },
  SET_KinshipType(state, KinshipTypes) {
    state.KinshipTypes = KinshipTypes
  },
  UPDATE_KinshipType(state, KinshipType) {
    const KinshipTypeIndex = state.KinshipTypes.findIndex((p) => p.ID == KinshipType.ID)
    Object.assign(state.KinshipTypes[KinshipTypeIndex], KinshipType)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.KinshipTypes.findIndex((p) => p.ID == itemId)
    state.KinshipTypes.splice(ItemIndex, 1)
},
}
